<template>
<aheader />
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-4 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="100" max="100"></progress>
    </div>
  </div>
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
          <div class="title is-4">
            ご注文ありがとうございました。
          </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="has-text-center is-size-5">
          ご注文に関するお問合せの際には下記注文番号をお伝えいただくとスムーズです。
          <br/>注文番号：{{orderID}}
        </div>
      </div>
    </div>
  </section>

</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      orderID: ""
    }
  },
  created() {
    this.orderID = this.$store.state.order_id;
    this.$store.state.order_id = "";
  },
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page);
    }
  }
}
</script>

<style scoped>

</style>
